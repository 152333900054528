import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ResponsiveService } from '@cf/core';
import { AuthService, connectCalendar } from '@cf/temp/services';
import { Observable } from 'rxjs';

import { AsyncPipe, NgIf } from '@angular/common';
import { OrganizationPickerComponent } from '@cf/features/organizations/admin';
import { ButtonModule } from '@cf/shared/ui/button';
import { CfIconDirective, IconModule } from '@cf/shared/ui/icons';
import { LogosModule } from '@cf/shared/ui/logos';
import { TypographyModule } from '@cf/shared/ui/typography';
import { AccountQuery, AccountService } from '@cf/temp/services';
import { Actions } from '@ngneat/effects-ng';
import { TranslateModule } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { CreateButtonComponent } from '../create-button/create-button.component';

@Component({
  selector: 'cf-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  imports: [
    IconModule,
    NgIf,
    AsyncPipe,
    LogosModule,
    CreateButtonComponent,
    TypographyModule,
    TranslateModule,
    ButtonModule,
    CfIconDirective,
    OrganizationPickerComponent,
  ],
})
export class HeaderComponent implements OnInit {
  toggleSidenav = output();

  isTouch$: Observable<boolean> = this.responsiveService.isTouch$;

  showCalendarAlert$ = this.accountQuery.showCalendarAlert$;

  showCalendarAlert!: boolean;

  #actions = inject(Actions);

  constructor(
    public readonly authService: AuthService,
    public readonly accountService: AccountService,
    private readonly accountQuery: AccountQuery,
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.router.events.pipe(filter((e) => e instanceof NavigationEnd)).subscribe((res: any) => {
      const url = res.url as string;
      this.showCalendarAlert = url.includes('dashboard') || url.includes('meeting/group/view');
      this.cd.detectChanges();
    });
  }

  openWindow() {
    this.#actions.dispatch(connectCalendar());
  }
}
