import { inject, Injectable } from '@angular/core';
import { CfDialogService } from '@cf/shared/dialog';
import {
  AuthService,
  CalendarStoreService,
  loadInvitations,
  loadOrganizations,
  manageCalendars,
  manageInvitation,
  OrganizationInvitations,
  OrganizationsService,
  OrganizationsStore,
  removeOrganization,
} from '@cf/temp/services';
import { createEffect, ofType } from '@ngneat/effects';
import { EMPTY, exhaustMap, map, merge, mergeMap } from 'rxjs';
import { CalendarSettingsModal, ReviewInvitationModal } from '../modals';
import { RemoveOrganizationComponent } from '../modals/remove-organization/remove-organization.component';

@Injectable({
  providedIn: 'root',
})
export class OrgDialogEffects {
  #organizationsStore = inject(OrganizationsStore);
  #dialogService = inject(CfDialogService);
  #organizationsService = inject(OrganizationsService);
  #authService = inject(AuthService);
  #calendarStoreService = inject(CalendarStoreService);

  manageInvitation$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(manageInvitation),
        exhaustMap((props) => this.handleInvitation(props.invitation, props.token)),
      ),
    { dispatch: true },
  );

  removeOrganization$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(removeOrganization),
        exhaustMap((res) => {
          const organisation = this.#organizationsStore
            .entities()
            .find((v) => v.id === res.organizationId);
          if (!organisation) {
            return EMPTY;
          }
          const ref = this.#dialogService.open({
            content: RemoveOrganizationComponent,
            data: {
              id: organisation.id,
              name: organisation.name,
            },
          });

          return ref.closed$.pipe(map(() => loadOrganizations()));
        }),
      ),
    { dispatch: true },
  );
  manageCalendars$ = createEffect(
    (actions) =>
      actions.pipe(
        ofType(manageCalendars),
        mergeMap((invitation) => this.handleCalendarManagement(invitation)),
      ),
    { dispatch: true },
  );

  handleInvitation(invitation: OrganizationInvitations, token?: string | null) {
    const ref = this.#dialogService.open({
      content: ReviewInvitationModal,
      data: {
        invitationId: invitation.id,
        invitorName: invitation.invitor_name,
        organizationName: invitation.organization.name,
        token: token ?? null,
      },
    });

    return merge(ref.closed$, ref.dismissed$).pipe(
      map((res) => {
        if (this.#authService.isAuthorized()) {
          if (res && this.#calendarStoreService.calendars.length) {
            return manageCalendars(invitation);
          } else {
            return [loadInvitations(), loadOrganizations()];
          }
        }
        return [];
      }),
    );
  }

  handleCalendarManagement(invitation: OrganizationInvitations) {
    return this.#organizationsService.fetchCalendars().pipe(
      map(
        (res) =>
          res.find((calendar) => calendar.organization_id === invitation.organization.id)
            ?.organization_id,
      ),
      mergeMap((organizationId) => {
        const ref = this.#dialogService.open({
          content: CalendarSettingsModal,
          data: { organizationId: organizationId ?? 0 },
        });

        return merge(ref.closed$, ref.dismissed$).pipe(
          map(() => [loadInvitations(), loadOrganizations()]),
        );
      }),
    );
  }
}
