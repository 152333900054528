import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterLink, RouterLinkActive, RouterLinkWithHref } from '@angular/router';
import { DebounceClickDirective, FormsCfModule } from '@cf/shared/forms/form-widgets';
import { CfIconDirective, IconModule } from '@cf/shared/ui/icons';
import { LogosModule, OrganizationAvatarComponent } from '@cf/shared/ui/logos';
import { HeaderUserFlowComponent } from './components/header-user-flow/header-user-flow.component';

import { TranslateModule } from '@ngx-translate/core';

import { ReactiveFormsModule } from '@angular/forms';
import { LanguagePickerComponent } from '@cf/features/i18n';
import { OrganizationPickerComponent } from '@cf/features/organizations/admin';
import { DropdownModule } from '@cf/shared/dropdown';
import { SelectNewComponent } from '@cf/shared/select';
import { ButtonModule } from '@cf/shared/ui/button';
import { TypographyModule } from '@cf/shared/ui/typography';
import { GoogleOneTapComponent } from './components/google-one-tap/google-one-tap.component';
import { UserNoticeComponent } from './components/user-notice/user-notice.component';

@NgModule({
  declarations: [HeaderUserFlowComponent, GoogleOneTapComponent, UserNoticeComponent],
  exports: [HeaderUserFlowComponent],
  imports: [
    CommonModule,
    RouterLinkWithHref,
    RouterLink,
    ButtonModule,
    LogosModule,
    IconModule,
    RouterLinkActive,
    FormsCfModule,
    DropdownModule,
    TypographyModule,
    TranslateModule,
    DebounceClickDirective,
    LanguagePickerComponent,
    SelectNewComponent,
    ReactiveFormsModule,
    CfIconDirective,
    OrganizationPickerComponent,
    OrganizationAvatarComponent,
  ],
})
export class LayoutModule {}
