<div cfDialogContent class="tw:max-w-[556px] dialog-wrapper">
  <button class="tw:absolute tw:top-6 tw:right-6" cfDialogDismiss>
    <svg size="sm" cfIcon="dialog_close" class="tw:text-gray-70" />
  </button>

  <div class="tw:flex tw:flex-col tw:mb-6">
    <div cfDialogTitle>{{ 'calendars.modal_title' | translate }}</div>

    <cf-text weight="600" class="tw:mt-4 tw:mb-3"> {{ 'calendars.default_settings' | translate }} </cf-text>

    <cf-select-new
      [provider]="calendarsProvider"
      [placeholder]="'Select Booking Calendar' | translate"
      [formControl]="bookingCalendarControl"
      appendTo=".dialog-wrapper"
      [labelTemplate]="optionsTmp"
      [optionTemplate]="optionsTmp"
    >
      <ng-template #optionsTmp let-calendar="item">
        <cf-icon size="sm" class="me-2" [icon]="$any(calendar.app_name.toLowerCase() + '_calendar')"></cf-icon>
        <span class="font-w-400 ms-2"> {{ calendar.email }} </span>
      </ng-template>
    </cf-select-new>

    @for (calendar of $calendars(); track calendar.id) {
    <cf-text weight="600" class="tw:mt-4"> {{ 'calendars.check_for_conflicts' | translate }} </cf-text>
    <mat-slide-toggle
      class="mt-3 d-block text-nowrap"
      color="primary"
      (change)="toggleCalendar(calendar, $event.checked)"
      [checked]="isChecked(calendar)"
    >
      <div class="d-flex align-items-center">
        <cf-text
          size="sm"
          class="ms-12 text-cut max-1"
          className="text-cut max-1"
          [matTooltip]="
                  calendar.email + (!!calendar.name.trim() ? ' (' + calendar.name + ')' : '')
                "
        >
          {{ calendar.email }} @if (!!calendar.name.trim()) { ({{ calendar.name }}) }
        </cf-text>
        <cf-icon
          size="sm"
          [icon]="$any(calendar.app_name.toLowerCase() + '_calendar')"
          class="tw:text-gray-70 tw:ms-2"
        ></cf-icon>
      </div>
    </mat-slide-toggle>

    @for (subCalendar of calendar.subcalendars; track subCalendar.id) {
    <mat-slide-toggle
      class="mt-2 ms-5 d-block"
      color="primary"
      (change)="toggleCalendar(calendar, $event.checked, subCalendar)"
      [checked]="isChecked(calendar, subCalendar)"
    >
      <cf-text size="sm" class="ms-12"> {{ subCalendar.name }} </cf-text>
    </mat-slide-toggle>
    } }
  </div>

  <div cfDialogButtons>
    <button cfButton [loading]="(loading.loadingState$ | async) ?? false" (click)="save()">
      <cf-button-loading [loadingText]="'admin.saving' | translate"> {{ 'save' | translate }} </cf-button-loading>
    </button>
  </div>
</div>
