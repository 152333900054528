/* eslint-disable */

export function initAnalytics() {
  // GTM
  (function (w: any, d: any, s: any, l: any, i: any) {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    const f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', 'GTM-WB39GZWW');
  // @ts-ignore
  // Hotjar
  (function (h: any, o: any, t: any, j: any, a: any, r: any) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = { hjid: 5162982, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');

  // @ts-ignore
  // helpscout
  !(function (e: any, t: any, n: any) {
    function a() {
      const e = t.getElementsByTagName('script')[0],
        n = t.createElement('script');
      (n.type = 'text/javascript'),
        (n.async = !0),
        (n.src = 'https://beacon-v2.helpscout.net'),
        e.parentNode.insertBefore(n, e);
    }
    if (
      ((e.Beacon = n =
        function (t: any, n: any, a: any) {
          e.Beacon.readyQueue.push({ method: t, options: n, data: a });
        }),
      (n.readyQueue = []),
      'complete' === t.readyState)
    )
      return a();
    e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1);
  })(window, document, (window as any).Beacon || function () {});

  (window as any).Beacon('init', '8ba087ec-002f-474d-ad6c-1210a9d8c3f7');
}
