import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { LoadingService } from '@cf/core';
import {
  CfDialogButtonsDirective,
  CfDialogContentDirective,
  CfDialogDismissDirective,
  CfDialogTitleDirective,
  injectDialogData,
  injectDialogRef,
  ModalModule,
} from '@cf/shared/dialog';
import { ButtonDirective, ButtonLoadingComponent, ButtonModule } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { OrganizationsService } from '@cf/temp/services';
import { TranslateModule } from '@ngx-translate/core';
import { catchError, finalize, of, take } from 'rxjs';

interface ReviewInvitationData {
  invitationId: number;
  invitorName: string;
  organizationName: string;
  token?: string | null;
}

@Component({
  selector: 'cf-review-invitation',
  standalone: true,
  imports: [
    ButtonModule,
    TranslateModule,
    ModalModule,
    CfDialogTitleDirective,
    CfDialogContentDirective,
    CfDialogDismissDirective,
    ButtonDirective,
    CfIconDirective,
    CfDialogButtonsDirective,
    ButtonLoadingComponent,
    AsyncPipe,
  ],
  template: `
    <div cfDialogContent class="tw:max-w-[556px]">
      <button class="tw:absolute tw:top-6 tw:right-6" cfDialogDismiss>
        <svg size="sm" cfIcon="dialog_close" class="tw:text-gray-70" />
      </button>

      <div class="tw:flex tw:flex-col tw:gap-4 tw:mb-6">
        <div cfDialogTitle>{{ 'admin.new_organization_invitation' | translate }}</div>

        <div>
          @if (data.invitorName && data.organizationName) {
            <div class="tw:text-gray-70 tw:text-base">
              <strong class="tw:font-medium">{{ data.invitorName }}</strong>
              {{ 'admin.invitation' | translate }}
              <strong class="tw:font-medium">{{ data.organizationName }}</strong>
              {{ 'admin.invitation_organization' | translate }}
            </div>
          }
        </div>
      </div>

      <div cfDialogButtons>
        <button
          cfButton
          variant="warning"
          disableOnRequest
          isLoadingBtn
          (click)="declineInvitation()"
          [loading]="(loading.loadingState$ | async) ?? false"
        >
          <cf-button-loading [loadingText]="'loading' | translate">
            {{ 'admin.decline_invitation' | translate }}
          </cf-button-loading>
        </button>
        <button
          cfButton
          disableOnRequest
          isLoadingBtn
          (click)="acceptInvitation()"
          [loading]="(loading.loadingState$ | async) ?? false"
        >
          <cf-button-loading [loadingText]="'loading' | translate">
            {{ 'admin.accept_invitation' | translate }}
          </cf-button-loading>
        </button>
      </div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewInvitationModal {
  loading = inject(LoadingService);
  ref = injectDialogRef<boolean>();
  data = injectDialogData<ReviewInvitationData>();

  #organizationsService = inject(OrganizationsService);

  acceptInvitation(): void {
    const request$ = this.data.token
      ? this.#organizationsService.acceptInvitationByToken(this.data.token)
      : this.#organizationsService.acceptInvitation(this.data.invitationId);

    request$
      .pipe(
        take(1),
        catchError(() => {
          this.ref.close(false);
          return of(false);
        }),
      )
      .subscribe((organisation) => {
        this.ref.close(organisation as never);
      });
  }

  declineInvitation(): void {
    const request$ = this.data.token
      ? this.#organizationsService.declineInvitationByToken(this.data.token)
      : this.#organizationsService.declineInvitation(this.data.invitationId);

    request$
      .pipe(
        take(1),
        finalize(() => this.ref.close(false)),
        catchError(() => {
          this.ref.close(false);
          return of(false);
        }),
      )
      .subscribe();
  }
}
