import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'cf-back-button',
  templateUrl: './back-button.component.html',
  styleUrl: './back-button.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BackButtonComponent {
  @Input() link!: string | null | boolean;

  constructor(
    private readonly location: Location,
    private readonly router: Router,
  ) {}

  public back() {
    if (typeof this.link === 'boolean') {
      this.location.back();
      return;
    }
    if (this.link) {
      void this.router.navigate([this.link]);
      return;
    }
    this.location.back();
  }
}
