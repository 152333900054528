import { NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Locale, LocaleOptions } from '@cf/core';
import { DropdownModule } from '@cf/shared/dropdown';
import { CfIconDirective, IconModule } from '@cf/shared/ui/icons';
import { TypographyModule } from '@cf/shared/ui/typography';
import { FlagDirective } from '../../directives';

@Component({
  selector: 'cf-language-picker',
  imports: [
    NgIf,
    NgForOf,
    DropdownModule,
    TypographyModule,
    IconModule,
    FlagDirective,
    CfIconDirective,
  ],
  templateUrl: './language-picker.component.html',
  styleUrl: './language-picker.component.scss',
})
export class LanguagePickerComponent {
  LocaleOptions = LocaleOptions;

  currentLocale: Locale = LocaleOptions[0];

  selectLanguage(locale: Locale) {
    this.currentLocale = locale;
  }
}
