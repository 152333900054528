<cf-dropdown placement="bottom-right" [showArrow]="true">
  <div class="d-flex align-items-center" cf-dropdown-toggle>
    <svg [flag]="currentLocale.abbr.toLowerCase()"></svg>
    <cf-text class="ms-2" color="black" weight="500">{{ currentLocale.abbr.toUpperCase() }}</cf-text>
  </div>

  <cf-dropdown-item *ngFor="let option of LocaleOptions" (click)="selectLanguage(option)">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex">
        <svg [flag]="option.abbr.toLowerCase()"></svg>
        <cf-text class="ms-3" color="black" [weight]="option.abbr === currentLocale.abbr ? '500' : '400'">
          {{ option.lang }}
        </cf-text>
      </div>

      <svg *ngIf="option.abbr === currentLocale.abbr" cfIcon="check_mark" class="tw:text-green-70"></svg>
    </div>
  </cf-dropdown-item>
</cf-dropdown>
