<div cfDialogContent class="tw:max-w-[496px]">
  <button class="tw:absolute tw:top-6 tw:right-6" cfDialogDismiss>
    <svg size="sm" cfIcon="dialog_close" class="tw:text-gray-70" />
  </button>

  <div class="tw:flex tw:flex-col tw:gap-4 tw:mb-6">
    <div cfDialogTitle>{{ "admin.delete-dialog.title" | translate }}</div>

    <div class="tw:flex tw:flex-col tw:gap-4">
      <div>
        {{ "admin.delete-dialog.description" | translate | translateCut: 0 }}
        <strong class="tw:font-medium">{{ data.name }}</strong>
        {{ "admin.delete-dialog.description" | translate | translateCut: 1 }}
      </div>

      <div class="tw:space-y-2 tw:text-base/6">
        <div class="tw:flex tw:gap-2">
          <span class="tw:size-2 tw:mt-2 tw:rounded-full tw:bg-blue-50 tw:shrink-0"></span>
          <span>{{ "admin.delete-dialog.consequences.access" | translate }}</span>
        </div>
        <div class="tw:flex tw:gap-2">
          <span class="tw:size-2 tw:mt-2 tw:rounded-full tw:bg-blue-50 tw:shrink-0"></span>
          <span>{{ "admin.delete-dialog.consequences.data" | translate }}</span>
        </div>
        <div class="tw:flex tw:gap-2">
          <span class="tw:size-2 tw:mt-2 tw:rounded-full tw:bg-blue-50 tw:shrink-0"></span>
          <span>{{ "admin.delete-dialog.consequences.irreversible" | translate }}</span>
        </div>
      </div>

      <div>
        <p class="tw:mb-2">
          {{ "admin.delete-dialog.confirm-hint" | translate | translateCut: 0
          }}<span>{{ "admin.delete-dialog.delete-text" | translate }}</span
          >{{ "admin.delete-dialog.confirm-hint" | translate | translateCut: 1 }}
        </p>
        <cf-form-field>
          <input
            cf-input
            [(ngModel)]="deleteText"
            class="form-control"
            #deleteTextRef="ngModel"
            [placeholder]="'admin.delete-dialog.placeholder' | translate"
            [pattern]="'^' + ('admin.delete-dialog.delete-text' | translate) + '$'"
          />
          <cf-input-error [error]="deleteTextRef.errors">{{
            "admin.delete-dialog.placeholder" | translate
          }}</cf-input-error>
        </cf-form-field>
      </div>
    </div>
  </div>

  <div cfDialogButtons>
    <button cfButton variant="secondary" color="secondary" cfDialogDismiss>
      {{ "cancel" | translate }}
    </button>
    <button
      cfButton
      [disabled]="!isDeleteEnabled()"
      (click)="submit()"
      [loading]="(loading.loadingState$ | async) ?? false"
    >
      <cf-button-loading [loadingText]="'admin.delete-dialog.deleting' | translate">
        {{ "admin.delete-dialog.delete-button" | translate }}
      </cf-button-loading>
    </button>
  </div>
</div>
