/// <reference types="@angular/localize" />

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { persistState } from '@datorama/akita';
import * as Sentry from '@sentry/angular';
import { environment } from './environments/environment';

import { initAnalytics } from './analytics';
import { AppModule } from './app/app.module';

persistState();

if (environment.production) {
  enableProdMode();

  Sentry.init({
    dsn: 'https://d68285913518454faa1c6707ddb7ef4d@sentry.bitstudios.com/40',
    environment: environment.sentryEnv,
    release: environment.buildVersion,
    enabled: !location.hostname.includes('localhost'),
    integrations: [
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
      Sentry.browserApiErrorsIntegration({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        XMLHttpRequest: false,
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [500],
      }),
    ],
    tracePropagationTargets: [environment.envName, environment.sentryEnv, environment.apiUrl],
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      console.log(event);
      console.log(hint);
      const error = hint.originalException as any;
      if (error && error.message && error.message.match(/NG0911/i)) {
        event.fingerprint = ['NG0911'];
      }
      return event;
    },
  });
  if (environment.envName === 'prod') {
    initAnalytics();
  }
}
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
