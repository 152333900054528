@use '../../../../../../src/scss/utils/breakpoints' as breakpoints;

cf-toast-container {
  display: block;
  position: fixed;
  top: 5px;
  right: 5px;
  z-index: 1200;

  .toast {
    --bs-toast-max-width: 430px;

    @include breakpoints.lt-md {
      --bs-toast-max-width: 360px;
    }
  }

  ngb-toast {
    border: none !important;
    border-radius: 10px !important;
    margin-bottom: 12px;

    .toast-header {
      align-items: baseline;

      background-color: inherit;
      color: inherit;
      padding: 20px 24px;

      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;

      border-radius: 10px;
      border: 1px solid var(--base-green-70);
      background: var(--base-green-10);

      .btn-close {
        opacity: 1;
        width: 0.5rem;
        height: 0.5rem;
        filter: invert(16%) sepia(12%) saturate(11%) hue-rotate(9deg) brightness(98%) contrast(88%);
      }
    }

    .toast-body {
      display: none;
    }

    &.danger {
      .toast-header {
        border: 1px solid var(--base-red-50);
        background: var(--base-red-10);
      }
    }

    &.info {
      .toast-header {
        border: 1px solid var(--base-blue-50);
        background: var(--base-blue-5);
      }
    }

    &.warning {
      .toast-header {
        border: 1px solid var(--base-yellow-60);
        background: var(--base-yellow-10);
        align-items: baseline;

        header {
          font-size: 12px;
        }
      }
    }

    //&.bg-danger {
    //  background-color: var(--base-red-50);
    //}
    //
    //&.bg-success {
    //  background-color: var(--base-green-70);
    //}
  }
}
