import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LoadingService } from '@cf/core';
import {
  CfDialogButtonsDirective,
  CfDialogContentDirective,
  CfDialogDismissDirective,
  CfDialogRef,
  CfDialogTitleDirective,
  injectDialogData,
  ModalModule,
} from '@cf/shared/dialog';
import {
  FormFieldComponent,
  InputDirective,
  InputErrorComponent,
} from '@cf/shared/forms/form-field';
import { ButtonDirective, ButtonLoadingComponent, ButtonModule } from '@cf/shared/ui/button';
import { CfIconDirective } from '@cf/shared/ui/icons';
import { OrganizationsService } from '@cf/temp/services';
import { Actions } from '@ngneat/effects-ng';
import { TranslateModule, TranslatePipe, TranslateService } from '@ngx-translate/core';
import { NgxTranslateCutPipe } from 'ngx-translate-cut';
interface RemoveOrganizationData {
  name: string;
  id: number;
}

@Component({
  selector: 'cf-remove-organization',
  templateUrl: './remove-organization.component.html',
  standalone: true,
  imports: [
    ButtonModule,
    TranslateModule,
    ModalModule,
    FormsModule,
    CfDialogTitleDirective,
    CfDialogContentDirective,
    CfDialogDismissDirective,
    ButtonDirective,
    CfIconDirective,
    CfDialogButtonsDirective,
    ButtonLoadingComponent,
    AsyncPipe,
    TranslatePipe,
    NgxTranslateCutPipe,
    FormFieldComponent,
    InputDirective,
    InputErrorComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemoveOrganizationComponent {
  #translate = inject(TranslateService);
  data = injectDialogData<RemoveOrganizationData>();
  loading = inject(LoadingService);
  ref = inject(CfDialogRef);
  #organizationsService = inject(OrganizationsService);
  #actions = inject(Actions);
  deleteText = '';

  isDeleteEnabled() {
    return this.deleteText === this.#translate.instant('admin.delete-dialog.delete-text');
  }

  submit() {
    if (!this.isDeleteEnabled()) return;

    this.#organizationsService.deleteOrganization(this.data.id).subscribe(() => {
      this.ref.close(true);
    });
  }
}
