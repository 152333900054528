import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { RouterLink } from '@angular/router';
import { DropdownModule } from '@cf/shared/dropdown';
import { ButtonDirective, ButtonModule } from '@cf/shared/ui/button';
import { CfIconDirective, IconModule } from '@cf/shared/ui/icons';
import { TypographyModule } from '@cf/shared/ui/typography';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'cf-create-button',
  template: `
    <cf-popover
      [popoverToggle]="popToggle"
      [popoverTemplate]="popContent"
      placement="auto"
      popoverClass="z-1003 p-0"
      [class.full-width]="fullWidth()"
      [fullWidth]="true"
      triggers="click"
      #popoverComponent
      class="tw:w-full"
    >
      <ng-template #popToggle>
        <button
          cfButton
          [size]="compact() ? 'icon' : 'default'"
          variant="yellow"
          [class.tw:w-full]="true"
        >
          @if (!compact()) {
            <span class="tw:truncate">
              {{ 'create' | translate }}
            </span>
          }
          <svg cfIcon="plus" class="tw:!mr-0 tw:!ml-0"></svg>
        </button>
      </ng-template>

      <ng-template #popContent>
        <div class="wrapper">
          <section
            routerLink="/meeting/create"
            (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
          >
            <img alt="One to One" src="assets/illustration/one_to_one_sm.svg" />
            <div>
              <h6 class="mb-1">Create Booking Page</h6>
              <cf-text color="grey-70" size="sm">
                Create a 1-to-1 or Group-to-1 Booking Page
              </cf-text>
            </div>
          </section>

          <!--          <section>-->
          <!--            <img-->
          <!--              alt="Group Poll"-->
          <!--              src="assets/illustration/group_events_sm.svg"-->
          <!--            />-->
          <!--            <div>-->
          <!--              <h6 class="mb-1">Group Events</h6>-->
          <!--              <cf-text color="grey-70" size="sm">-->
          <!--                Schedule calendar events across time zones and organizations-->
          <!--              </cf-text>-->
          <!--            </div>-->
          <!--          </section>-->

          <section
            routerLink="/meeting/group/create"
            (click)="popoverComponent.handlePopover(true); emitNavigate.emit()"
          >
            <img alt="Group events" src="assets/illustration/group_to_one_md.svg" />
            <div>
              <h6 class="mb-1">New Group</h6>
              <cf-text color="grey-70" size="sm">
                Create groups and then schedule with ease
              </cf-text>
            </div>
          </section>
        </div>
      </ng-template>
    </cf-popover>
  `,
  styleUrls: ['./create-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DropdownModule,
    ButtonModule,
    RouterLink,
    TypographyModule,
    IconModule,
    TranslateModule,
    CfIconDirective,
    ButtonDirective,
  ],
})
export class CreateButtonComponent {
  fullWidth = input<boolean>(false);
  compact = input<boolean>(false);
  emitNavigate = output();
}
